<template>
  <v-list-item>
    <v-list-item-title>
      <v-card class="pa-2 mt-3 elevation-0" @click="showHistory()"  data-type="showHistory">
        <v-icon class="mr-2 mt-n1">mdi-history</v-icon>
        <span>{{ info.name }}</span>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="pl-2">mdi-information</v-icon>
          </template>
          <span>
            <span class="font-weight-bold">{{ getName("version_info") }}</span>
            <div
              v-for="(col, header) in historyInfoHeader"
              :key="header"
              style="min-width: 350px"
            >
              <v-row no-gutters>
                <v-col cols="3">
                  <span class="font-weight-bold">{{ col.name }}</span
                  >:
                </v-col>
                <v-col>
                  <tableRowFieldValue
                    :row="row"
                    :header="header"
                    :currentTable="currentTable"
                    :result="{}"
                  ></tableRowFieldValue>
                </v-col>
              </v-row>
            </div>
          </span>
        </v-tooltip>
      </v-card>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import tableRowFieldValue from "@/commonComponents/tableRowFieldValue.vue";
export default {
  components: { tableRowFieldValue },
  props: ["info", "actionName", "currentTable", "row"],
  methods: {
    showHistory() {
      let uri = this.currentTable.tableShortUrl;

      var searchParams = new URLSearchParams(uri);
      searchParams.set("n_id", this.row.n_id);
      searchParams.set("n_id_key", this.row.n_id_key);
      searchParams.set("function", "showsHistoryContent");
      var newParams = searchParams.toString();

      let currentTable = this.currentTable;
      this.$set(currentTable, "id", "");
      this.$store.commit("tableChanged", currentTable);
      this.$router.push("?" + newParams);
    },
    //  emitEvent(element, value) {
    //   console.log(element, value);
    // },
  },
  computed: {
    historyInfoHeader() {
      let header = {};
      for (let [key, info] of Object.entries(this.currentTable.header)) {
        if (
          typeof info.historyInfo != "undefined" &&
          info.historyInfo == true
        ) {
          header[key] = info;
        }
      }
      return header;
    },
  },
};
</script>